import React, { useState } from "react";
import { Tabs, Tab, Table, Card, Container, Col, Row } from "react-bootstrap";

export default function TabLayout() {
  const [key, setKey] = useState("home");

  return (
    <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="mb-3"
    >
      <Tab eventKey="home" title="Home">
        Tab content for Home
      </Tab>
      <Tab eventKey="profile" title="Profile">
        Tab content for Profile
        <h1>Languages and Skills</h1>
        Languages Add the languages you know to your profile to qualify for more
        projects. Please use the following guide to determine what to enter for
        each language: [Conversational] You can read and write at a basic level.
        [Fluent] You can read the language almost perfectly and can write with
        few errors, but you are not a native speaker. [Native] You have spoken
        this language since birth or lived in a country where it is the dominant
        language for 10+ years. You read and write like a native. Language How
        well do you speak this language? English Fluent Skills Add your skills
        to your profile to qualify for more projects. Please include domains
        that you would feel comfortable answering questions for, perhaps from
        prior educational, professional, or hobby experience. Make sure to click
        "Update" below after making any changes. Skill Software Engineering >
        Python Software Engineering > JavaScript Software Engineering >
        TypeScript Skills and Background Add any other information you think
        would help us determine which projects you're best suited for. • Version
        Control Systems: Git (Github, GitLab) • Cloud Platforms: AWS, Azure, GCP
        • Operating Systems: Linux, Windows • Databases: Oracle SQL, Microsoft
        SQL, MariaDB, Postgres, MongoDB • Programming Languages: Python, Java,
        Javascript, Typescript • Frameworks: React (NextJS, NX), Angular,
        ExpressJS, Django, Spring Boot • Testing: Robot Framework, Pytest,
        PyUnit (Unittest), Cypress, Selenium • DevOps: ArgoCD, Jenkins,
        Terraform, Ansible, Docker Swarm, Kubernetes, Nagios • Other Skills:
        Full-Stack Development, Issue Tracking, Data Mapping, Communication,
        Problem-solving, Fast-paced Adaptability Licenses & Certifications • AWS
        Certified Developer - Associate o Earned: December 09, 2023 o Expires:
        December 09, 2026 • AWS Certified Cloud Practitioner o Earned: October
        28, 2023 o Expires: October 28, 2026 • Microsoft Certified: Azure
        Fundamentals o Earned: January 13, 2024 • Post Graduate Program in
        DevOps, Caltech - Center of Technology and Education o Earned: March,
        2024 • CCNA, Cisco o Earned: September 23, 2023 o Expires: September 23,
        2026 Profile Worker ID JTJ9WNYWQWNA Referral Code qqfaQXM First Name
        Paul Last Name Aglipay Email paglipay@gmail.com Email Notifications
        Enabled? Update Password ••••••••••••••• Update Password Confirmation
        PayPal Email paglipay@hotmail.com LinkedIn Profile URL
        https://www.linkedin.com/in/paul-aglipay-11564046/ Phone Number
        +13236106668 Verified? Delete my account
      </Tab>
      <Tab eventKey="support" title="Support" >
        Tab content for Contact
      </Tab>
      <Tab eventKey="code" title="Code of Conduct" >
        Tab content for Contact
      </Tab>
      <Tab eventKey="contact" title="Contact" >
        Tab content for Contact
      </Tab>
      <Tab eventKey="inbox" title="Inbox">
        <h4>Inbox</h4>
        <Container fluid={true}>
          <Row>
            <Col md="4">
              <Card>
                <Card.Header>✓ Feedback and Announcements ✓ Direct</Card.Header>
                Messages
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Message</th>
                      <th>Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>Message 1</td>
                      <td>9:00 AM</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>Message 2</td>
                      <td>9:30 AM</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Message 3</td>
                      <td>10:00 AM</td>
                    </tr>
                  </tbody>
                </Table>
              </Card>
            </Col>
            <Col md="8">
              <Card>
                <Card.Header>Message</Card.Header>
                Message
                <Card.Body>
                  <Card.Title>Message Title</Card.Title>
                  <Card.Text>Message Text</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Tab>
    </Tabs>
  );
}
