import React, { useState } from "react";
import { Link, BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import { Link, useLocation } from "react-router-dom";
import { Container, Navbar, Nav, NavDropdown } from "react-bootstrap";

import NoMatch from "./components/pages/NoMatch";
import logo from "./logo.svg";
import "./App.css";
// import AiChat from "./components/AiChat/AiChat";
// import Layout1 from "./components/pages/Layout1";
import Layout2 from "./components/pages/Layout2";
import LayoutB from "./components/pages/LayoutB";
import TabLayout from "./components/pages/TabLayout";
// import SocketLayout from "./components/pages/SocketLayout";
import CheckoutLayout from "./components/pages/CheckoutLayout";

function App() {
  // const [authenticated, setAuthenticated] = useState(false);

  // const authenticate = () => {
  //   setAuthenticated(true);
  // };

  // const deAuthenticate = () => {
  //   setAuthenticated(false);
  // };

  return (
    <>
      <Router>
        <Navbar
          bg="dark"
          variant="dark"
          sticky="top"
          expand="lg"
          className="bg-body-tertiary"
        >
          <Container fluid>
            <Navbar.Brand href="#home">PA</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link as={Link} to="/home">
                  Home
                </Nav.Link>
                <Nav.Link as={Link} to="/checkout">
                  Services
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>

            <Navbar.Collapse className="justify-content-end">
              <NavDropdown
                className="justify-content-end text-white"
                title="Paul Aglipay"
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item href="#action/3.1">Profile</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">Support</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">
                  Code of Conduct
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">Logout</NavDropdown.Item>
              </NavDropdown>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <div className="App">
          <Switch>
            <Route exact path={["/", "/home"]}>
              {/* <AiChat key={"1"} /> */}
              {/* <LayoutA key={"A"} /> */}
              <TabLayout key={"T"} />
              <LayoutB key={"B"} />
              <Layout2 key={"2"} />
            </Route>
            <Route exact path={["/checkout"]}>
              <CheckoutLayout key={"C"} />
            </Route>
            <Route>
              <NoMatch />
            </Route>
          </Switch>
        </div>
      </Router>
    </>
  );
}

export default App;
