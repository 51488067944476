import React, { useState, useEffect } from "react";
import Layout from "../Layout";
// import io from "socket.io-client";
import axios from "axios";
import newSocket from "../../utils/SocketIo/SocketIo";

import { Button, Card, Toast } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
// import { json } from "body-parser";
// import { useDispatch, useSelector } from "../../store/store";
// import { useSelector, useDispatch } from 'react-redux'
// import { setLayout } from "../actions";
// import {
//   setLayoutState,
//   setSections,
// } from "../../store/slices/layoutSlice";

function SocketLayout(props) {
  const testFunc = (data) => {
    console.log("testFunc: ", data);
  };
  const [uuid, setUuid] = useState(uuidv4());
  const [showJoinButton, setShowJoinButton] = useState(true);
  const [socketId, setSocketId] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [counter, setCounter] = useState(0);
  const [input, setInput] = useState("");
  const [prompt, setPrompt] = useState("");
  const [socketLayoutTerminalData, setSocketLayoutTerminalData] = useState([
    "FAKE",
    "DATA",
  ]);
  const [jsonData, setJsonData] = useState([
    {
      code: `s0`,
      componentType: "SerialConsole",
      props: {
        id: `${uuid}`,
        terminal: ["###SocketLayout TERMINAL###\n"],
        input,
        prompt,
        room: props.room,
      },
    },
    {
      code: `cap0`,
      componentType: "SerialConsoleCapture",
      props: {
        id: `${uuid}`,
        terminal: ["###SocketLayout TERMINAL###\n"],
        input,
        prompt,
        room: props.room,
      },
    },
  ]);
  const [sections, setSections] = useState([
    {
      title: "Serial",
      fluid: true,
      cols: ["6", "6"],
      // cols: ["z"],
      // featureTypesArry: ["2", "1", "c", "c"],
      featureTypesArry: [`s0`, `cap0`],
    },
  ]);
  const [socket, setSocket] = useState(null);
  const [room, setRoom] = useState(props.room);
  //   const dispatch = useDispatch();
  //   const { sections } = useSelector(setLayou/tState);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("Alert!");
  const [fastMode, setFastMode] = useState(false);

  // Function to show the toast for a few seconds
  const showToastForSeconds = (seconds) => {
    setShowToast(true);
    setTimeout(() => setShowToast(false), seconds * 1000);
  };

  useEffect(() => {
    // Connect to Socket.IO server
    setShowToast(true);
  }, [toastMessage]);

  useEffect(() => {
    // Connect to Socket.IO server
    setSocket(newSocket);

    // joinRoom();
    // Clean up on component unmount
    return () => {
      newSocket.disconnect();
    };
  }, []);

  const onClickHandler = () => {
    const data = {
      "./json/mongodb/portfolio_serial_con_session/out.json": { room, jsonData, sections },
      jobs: [{ import: "Key" }, "my_packages/MongoObj/MongoObj-save-serial_con_session.json"],
    };
    // data.append('file', state.selectedFile)
    axios
      .post("/api/dtree/start/mongodb", data, {
        // receive two    parameter endpoint url ,form data
      })
      .then((res) => {
        // then print response status
        console.log(res.statusText);
        console.log(res.data);
      });
  };

  // useEffect(() => {
  //   console.log("socketLayoutTerminalData: ", socketLayoutTerminalData);
  //   // setJsonData([
  //   //   { ...jsonData[0], props: { ...jsonData[0].props, terminal: socketLayoutTerminalData } },
  //   //   ...jsonData.slice(1)
  //   // ]);

  // }, [socketLayoutTerminalData]);

  useEffect(() => {
    if (socket) {
      // Listen for incoming layouts
      socket.on("layout", (layout) => {
        console.log("layout.layout.room: ", layout.layout.room);
        console.log("layout: ", layout);
        if (layout.layout.room === room) {
          setJsonData(layout.layout.jsonData);
          setSections(layout.layout.sections);
        }
      });

      const handleTerminal = (terminal) => {
        console.log("terminal.terminal.room: ", terminal.terminal.room);
        console.log("terminal: ", terminal);
        if (terminal.terminal.room === room) {
          console.log(
            "terminal.terminal.terminalData: ",
            terminal.terminal.terminalData
          );
          console.log(
            "socketLayoutTerminalData before: ",
            socketLayoutTerminalData
          );
          console.log("fastMode before: ", fastMode);
          console.log(
            "terminal.terminal.terminalData[0]: ",
            terminal.terminal.terminalData
              ? terminal.terminal.terminalData[0]
              : "!!!"
          );
          // setSocketLayoutTerminalData([...socketLayoutTerminalData, terminal.terminal.terminalData[0]]);
          // setSocketLayoutTerminalData([...socketLayoutTerminalData, terminal.terminal.terminalData[0]]);
          // Use functional form of setState to ensure latest state value is used
          // setSocketLayoutTerminalData((prevData) => [
          //   ...prevData,
          //   terminal.terminal.terminalData[0],
          // ]);

          setJsonData((prevData) => [
            {
              ...prevData[0],
              props: {
                ...prevData[0].props,
                terminal: [
                  ...prevData[0].props.terminal,
                  `${
                    terminal.terminal.terminalData
                      ? terminal.terminal.terminalData[0]
                      : "!!!"
                  }\n`,
                ],
                input: terminal.terminal.input,
              },
            },
            {
              ...prevData[1],
              props: {
                ...prevData[1].props,
                terminal: [
                  ...prevData[1].props.terminal,
                  `${terminal.terminal.terminalData[0]}\n`,
                ],
                input: terminal.terminal.input,
              },
            },
            // ...prevData.slice(1),
          ]);

          // setJsonData([
          //   { ...jsonData[0], props: { ...jsonData[0].props, terminal: socketLayoutTerminalData } },
          //   ...jsonData.slice(1)
          // ]);
          // setJsonData([
          //   {
          //     code: `s0`,
          //     componentType: "SerialConsole",
          //     props: {
          //       id: `${uuid}`,
          //       terminal: socketLayoutTerminalData ? socketLayoutTerminalData : [],
          //       input,
          //       room: props.room,
          //     },
          //   },
          //   {
          //     code: `cap0`,
          //     componentType: "SerialConsoleCapture",
          //     props: {
          //       id: `${uuid}`,
          //       terminal: ["###SocketLayout TERMINAL###\n"],
          //       input,
          //       room: props.room,
          //     },
          //   }
          // ]);
          setSections([
            {
              title: "Serial",
              fluid: true,
              cols: ["7", "4"],
              // cols: ["z"],
              // featureTypesArry: ["2", "1", "c", "c"],
              featureTypesArry: [`s0`, `cap0`],
            },
          ]);
        }
      };
      // Listen for incoming layouts
      socket.on("terminal", handleTerminal);

      socket.on("yourID", (id) => {
        console.log("socket_id:", id);
        joinRoom();
        setSocketId(id);
      });

      socket.on("connect_error", async (error) => {
        console.error("Connection error:", error);
        // Handle the error
        await showJoinButton(true);
        setShowToast(true);
        setToastMessage("Connection error:", error);
      });

      socket.on("disconnect", () => {
        console.log("Disconnected from server");
        // Handle disconnection
        // showJoinButton(true);
        // setToastMessage("Disconnected from server");
        // setShowToast(true);
      });

      socket.on("reconnect_attempt", async (attemptNumber) => {
        console.log(`Attempting to reconnect (${attemptNumber})`);
        // Your reconnection logic here
        await showJoinButton(true);
        setToastMessage(`Attempting to reconnect (${attemptNumber})`);
        setShowToast(true);
      });
    }
  }, [socket]);

  useEffect(() => {
    console.log("counter: ", counter);
  }, [counter]);

  // useEffect(() => {
  //   console.log("jsonData: ", jsonData);
  // }, [jsonData]);

  const joinRoom = () => {
    console.log("joinRoom: ", room);
    setShowJoinButton(false);
    if (socket && room.trim() !== "") {
      socket.emit("join", {
        name: uuid + "-react",
        room: props.room,
      });
    }
  };

  const sendRoom = () => {
    console.log("sendRoom: ", room);
    setCounter(counter + 1);
    setToggle(!toggle);
    setShowJoinButton(false);
    if (socket && room.trim() !== "") {
      // fastMode
      false
        ? socket.emit("sendTerminal", {
            room: props.room,
            terminalData: jsonData[0].props.terminal.concat([
              `${socketId}-${props.room}-${counter}\n`,
            ]),
          })
        : socket.emit(
            "sendLayout",
            toggle
              ? {
                  room: props.room,
                  jsonData: [
                    {
                      code: `s0`,
                      componentType: "SerialConsole",
                      props: {
                        id: `${uuid}`,
                        terminal: jsonData[0].props.terminal,
                        input,
                        prompt,
                        // jsonData: jsonData,
                        room: props.room,
                        senderUuId: uuid,
                      },
                    },
                  ],
                  sections: [
                    {
                      title: "My ChatGPT Interaction Showcase",
                      fluid: true,
                      cols: ["12", "6"],
                      featureTypesArry: [`s0`],
                    },
                  ],
                }
              : {
                  room: props.room,
                  jsonData: [
                    {
                      code: `s0`,
                      componentType: "SerialConsole",
                      props: {
                        id: `${uuid}`,
                        terminal: jsonData[0].props.terminal,
                        input,
                        prompt,
                        // jsonData: jsonData,
                        room: props.room,
                        senderUuId: uuid,
                      },
                    },
                    {
                      code: `q0`,
                      componentType: "StripeCheckout",
                      props: {
                        title: "Donate to Serial Console Project",
                        description:
                          "If you enjoy this project and would like to support it, please feel free to make a donation.",
                        price: `price_1PCMQU2UeSQIV463oAe2JBgh`,
                        price_usd: 5.0,
                        mode: "subscription",
                        successUrl: `https://paglipay-cyoa-express.herokuapp.com/home`,
                        cancelUrl: `https://paglipay-cyoa-express.herokuapp.com/checkout`,
                      },
                    },
                  ],
                  sections: [
                    {
                      title: "My ChatGPT Interaction Showcase",
                      fluid: true,
                      cols: ["8", "4"],
                      featureTypesArry: [`s0`, `q0`],
                    },
                  ],
                }
          );
    }
  };

  //   const sendLayout = () => {
  //     if (socket) {
  //       socket.emit("customer layout", { room, layout: inputValue });
  //       setInputValue("");
  //     }
  //   };

  return (
    <Card style={{ height: "100%" }}>
      {/* <pre style={{textAlign: "left", height:"200px", overflow:"auto"}}>{JSON.stringify(socketLayoutTerminalData, null, 2)}</pre> */}
      <Button onClick={onClickHandler}>Click Me</Button>
      <Toast
        show={showToast}
        onClose={() => setShowToast(false)}
        style={{
          position: "absolute",
          top: 10,
          right: 10,
        }}
        delay={3000} // Set the delay to hide the toast automatically after 3 seconds
        autohide
      >
        <Toast.Header closeButton={false}>
          <strong className="mr-auto">Notification:</strong>
        </Toast.Header>
        <Toast.Body>
          {toastMessage}
          <br />
          <br />
          This is a toast message that will disappear in a few seconds.
        </Toast.Body>
      </Toast>
      <div style={{ textAlign: "right" }}>
        {/* <Button size="sm" variant="outline-primary" onClick={() => {}}>
          +
        </Button>
        <Button size="sm" variant="outline-primary" onClick={() => {}}>
          -
        </Button> */}
      </div>
      <Card.Header>
        <h1>{props.room}</h1>
      </Card.Header>
      {/* <Button
        id="toggle"
        variant={fastMode ? "primary" : "outline-primary"}
        onClick={() => {
          setFastMode(!fastMode);
          setShowToast(!fastMode)
          setSocketLayoutTerminalData([...socketLayoutTerminalData, fastMode]);

        }}
      >
        Fast Mode: {`${fastMode ? "ON" : "OFF"}`}
      </Button> */}
      {/* <Card.Body> */}
      {showJoinButton && (
        <Button variant="warning" onClick={joinRoom}>
          Join Room {room}
        </Button>
      )}
      {!showJoinButton && (
        <Button variant="success" onClick={sendRoom}>
          Send to Room {room}
        </Button>
      )}
      <Layout jsonData={jsonData} sections={sections} />
      {/* </Card.Body> */}
    </Card>
  );
}

export default SocketLayout;
