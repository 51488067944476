import React, { useEffect, useState } from "react";
import { Tab, Tabs, Form, Button } from "react-bootstrap";
import axios from "axios";
export default function SerialConsoleCapture(props) {
  const [captures, setCaptures] = useState({});

  useEffect(() => {
    console.log("SerialConsoleCapture useEffect props: ", props);
    if (props.input && !props.input.includes("<enter>")) {
      setCaptures({
        ...captures,
        [props.input.trim()]: props.terminal,
      });
    }
  }, [props]);

  const onClickHandlerSave = () => {
    const data = {
      "./json/mongodb/portfolio_serial_con_session/out.json": {
        room: props.room,
        captures
      },
      jobs: [{ import: "Key" }, "my_packages/MongoObj/MongoObj-save-serial_con_session.json"],
    };
    // data.append('file', state.selectedFile)
    axios
      .post("/api/dtree/start/mongodb", data, {
        // receive two    parameter endpoint url ,form data
      })
      .then((res) => {
        // then print response status
        console.log(res.statusText);
        console.log(res.data);
      });
  };

  return (
    <>
      <Button onClick={onClickHandlerSave}>Save Session</Button>
      <Tabs defaultActiveKey="profile" id="uncontrolled-tab-example">
        {Object.keys(captures).map(
          (key, index) =>
            key && (
              <Tab eventKey={key} title={key.split('\n')[0]}>
                <div style={{ height: "500px", overflow: "hidden" }}>
                  <Form.Control
                    key={`pre-${index}`}
                    style={{
                      height: "100%",
                      textAlign: "left",
                      backgroundColor: "black",
                      color: "greenyellow",
                      fontFamily: "monospace",
                      // overflow: "auto",
                      // overflowX: "auto",
                      // whiteSpace: "pre",
                    }}
                    as="textarea"
                    rows={6}
                    value={captures[key].join("")}
                    onChange={(e) =>
                      setCaptures({
                        ...captures,
                        [key]: [e.target.value],
                      })
                    }
                  />
                </div>
              </Tab>
            )
        )}
      </Tabs>
    </>
  );
}
