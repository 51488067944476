import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, ToggleButton, Form } from "react-bootstrap";
import Layout from "../Layout";

function LayoutB() {
  const radios = [
    { name: "One", value: "12" },
    { name: "Two", value: "6" },
    { name: "Three", value: "4" },
  ];
  const radiosView = [
    { name: "One", value: ["8", "2", "2"] },
    { name: "Two", value: ["2", "8", "2"] },
    { name: "Three", value: ["2", "2", "8"] },
  ];
  const [col, setCol] = useState("4");
  const [cols, setCols] = useState(["6", "6", "6", "6", "6", "6", "6", "6"]);
  const [jsonData, setJsonData] = useState([
    {
      code: "card",
      componentType: "CardPlaceholder",
      props: {
        title: "SocketLayout1",
        room: "room1",
      },
    },
    {
      code: "sl1",
      componentType: "SocketLayout",
      props: {
        title: "SocketLayout1",
        room: "room1",
      },
    },
    {
      code: "sl2",
      componentType: "SocketLayout",
      props: {
        title: "SocketLayout2",
        room: "room2",
      },
    },
    {
      code: "sl3",
      componentType: "SocketLayout",
      props: {
        title: "SocketLayout3",
        room: "room3",
      },
    },
  ]);
  const [sections, setSections] = useState([
    {
      title: "My ChatGPT Interaction Showcase",
      fluid: true,
      cols,
      featureTypesArry: ["sl1", "sl2", "sl3"],
    },
  ]);
  const [counter, setCounter] = useState(4);

  useEffect(() => {
    console.log("LayoutB: useEffect");
  }, []);

  useEffect(() => {
    console.log("col: useEffect");
    setCols([col, col, col, col, col, col, col, col]);
  }, [col]);

  useEffect(() => {
    console.log("cols: useEffect");
    setSections([
      {
        title: "My ChatGPT Interaction Showcase",
        fluid: true,
        cols,
        featureTypesArry: ["sl1", "sl2", "sl3"],
      },
    ]);
  }, [cols]);

  const handleClick = () => {
    setJsonData([
      ...jsonData,

      {
        code: `sl${counter}`,
        componentType: "SocketLayout",
        props: {
          title: `SocketLayout${counter}`,
          room: `room${counter}`,
        },
      },
    ]);
    setSections([
      {
        title: "My ChatGPT Interaction Showcase",
        fluid: true,
        cols,
        featureTypesArry: sections[0]["featureTypesArry"].concat([
          `sl${counter}`,
        ]),
      },
    ]);
    setCounter(counter + 1);
  };

  return (
    <>
      <Form>
        <Form.Group>
          <Form.Label>Col</Form.Label>
          <br />
          {/* <Button
            onClick={() => {
              setCol("12");
            }}
          >
            One
          </Button>
          <Button
            onClick={() => {
              setCol("6");
            }}
          >
            Two
          </Button>
          <Button
            onClick={() => {
              setCol("4");
            }}
          >
            Three
          </Button> */}
          <ButtonGroup>
            {radios.map((radio, idx) => (
              <ToggleButton
                key={idx}
                id={`radio-${idx}`}
                type="radio"
                variant={"outline-primary"}
                name="radio"
                value={radio.value}
                checked={col === radio.value}
                onChange={(e) => setCol(e.currentTarget.value)}
              >
                {radio.name}
              </ToggleButton>
            ))}
          </ButtonGroup>
          <br/>
          <ButtonGroup>
            {radiosView.map((radio, idx) => (
              <ToggleButton
                key={idx}
                id={`radio-${idx}`}
                type="radio"
                variant={"outline-primary"}
                name="radio"
                value={radio.value}
                // checked={cols === radio.value}
                onChange={(e) => {
                  
                  setSections([
                    {
                      title: "My ChatGPT Interaction Showcase",
                      fluid: true,
                      cols: radio.value,
                      featureTypesArry: ["sl1", "sl2", "sl3"],
                    },
                  ]);
                }}
              >
                {radio.name}
              </ToggleButton>
            ))}
          </ButtonGroup>
          {/* <Form.Control
            as="select"
            value={col}
            onChange={(e) => {
              setCol(e.target.value);
            }}
          >
            <option value="12">One</option>
            <option value="6">Two</option>
            <option value="4">Three</option>
          </Form.Control> */}
        </Form.Group>
      </Form>
      <Layout jsonData={jsonData} sections={sections} />
      <Button variant="primary" onClick={handleClick} size="lg">
        Add
      </Button>
    </>
  );
}

export default LayoutB;
