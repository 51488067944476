import React, { useEffect, useState, useRef } from "react";
import newSocket from "../../utils/SocketIo/SocketIo";
import { Button, Form, Row, Col, Container, Badge } from "react-bootstrap";

export default function SerialConsole(props) {
  async function getCannedCommands() {
    return new Promise((resolve, reject) => {
      resolve({
        "?": false,
        "Ctrl+Alt+6": false,
        enable: false,
        "terminal length 0": false,
        end: false,
        exit: false,
        "delete flash:vlan.dat\n\n\nwrite erase\n\nreload\n\n\n":false,
        "switch 2 renumber 1":false,
        "no ip domain lookup":false,
        "telnet 1.1.1.1 2003": false,
        "telnet 1.1.1.1 2007": false,
        "show version": false,
        "show running-config": false,
        "copy usbflash0:/cat9k_iosxe.17.09.05.SPA.bin flash:\n": false,
        "install add file flash:cat9k_iosxe.17.09.05.SPA.bin activate commit\ny\n": false,
        "install remove inactive": false,
        "show ip interface brief": false,
        "vtp mode transparent": false,
        "show vtp status": false,
        "show interfaces": false,
        "show vlan brief": false,
        "show interface status": false,
        "show interfaces trunk": false,
        "show interface f0/1 switchport": false,
        "show cdp neighbor": false,
        "show ip route": false,
        "show ip ospf neighbor": false,
        "show ip ospf interface": false,
        "show ip ospf database": false,
        "show ip ospf": false,
        "show ip bgp summary": false,
        "show ip bgp": false,
        "show ip bgp neighbors": false,
        "show arp": false,
        "show mac address-table": false,
        "show spanning-tree": false,
        "show crrypto isakmp sa": false,
        "show crypto ipsec sa": false,
        "show crypto engine connections active": false,
        "show crypto engine connections": false,
        "show access-lists": false,
        "show dhcp pool": false,
        "show dhcp binding": false,
        "show dhcp server statistics": false,
        "show ip nat translations": false,
        "show ip nat statistics": false,
        "show ip accounting": false,
        "show clock": false,
        "show logging": false,
        "show users": false,
        "show interfaces description": false,
        "show ip route ospf": false,
        "show ip route bgp": false,
        "show ip route static": false,
        "show ip route rip": false,
        "show ip route eigrp": false,
        "show ip route": false,
        "show ip route summary": false,
      });
    });
  }

  let selectedPort;
  console.log("SerialConsole props: ", props);
  //   let output;
  const inputRef = useRef(null);
  const [port, setPort] = useState(null);
  const [reader, setReader] = useState(null);
  const [isHost, setIsHost] = useState(props.id);
  const [output, setOutput] = useState(null);
  const [input, setInput] = useState(props.input ? props.input : "");

  const [prompt, setPrompt] = useState(
    props.prompt
      ? Array.isArray(props.prompt)
        ? props.prompt.join("\n")
        : props.prompt
      : ""
  );
  const [room, setRoom] = useState(props.room);
  const [socket, setSocket] = useState(null);
  const [toggle, setToggle] = useState(false);
  const [connected, setConnected] = useState(false);
  const [counter, setCounter] = useState(0);
  const [outputSplitLen, setOutputSplitLen] = useState(0);
  const [outputLen, setOutputLen] = useState(0);
  const [fastMode, setFastMode] = useState(false);
  const [immediatelySend, setImmediatelySend] = useState(false);
  const [cannedCommands, setCannedCommands] = useState({
    "?": false,
    enable: false,
    "terminal length 0": false,
    end: false,
    exit: false,
    "delete flash:vlan.dat\n\n\nwrite erase\n\nreload\n\n\n":false,
    "switch 2 renumber 1":false,
    "no ip domain lookup":false,
    "telnet 1.1.1.1 2003": false,
    "telnet 1.1.1.1 2007": false,
    "show version": false,
    "show running-config": false,
    "copy usbflash0:/cat9k_iosxe.17.09.05.SPA.bin flash:\n": false,
    "install add file flash:cat9k_iosxe.17.09.05.SPA.bin activate commit\ny\n": false,
    "install remove inactive": false,
    "show ip interface brief": false,
    "show interfaces": false,
    "show vlan brief": false,
    "show interface status": false,
    "show cdp neighbor": false,
    "show ip route": false,
    "show ip ospf neighbor": false,
    "show ip ospf interface": false,
    "show ip ospf database": false,
    "show ip ospf": false,
    "show ip bgp summary": false,
    "show ip bgp": false,
    "show ip bgp neighbors": false,
    "show arp": false,
    "show mac address-table": false,
    "show spanning-tree": false,
    "show crrypto isakmp sa": false,
    "show crypto ipsec sa": false,
    "show crypto engine connections active": false,
    "show crypto engine connections": false,
    "show access-lists": false,
    "show dhcp pool": false,
    "show dhcp binding": false,
    "show dhcp server statistics": false,
    "show ip nat translations": false,
    "show ip nat statistics": false,
    "show ip accounting": false,
    "show clock": false,
    "show logging": false,
    "show users": false,
    "show interfaces description": false,
    "show ip route ospf": false,
    "show ip route bgp": false,
    "show ip route static": false,
    "show ip route rip": false,
    "show ip route eigrp": false,
    "show ip route": false,
    "show ip route summary": false,
  });

  // const [dtree, setDtree] = useState({
  //   output: props.terminal ? props.terminal : [],
  // });
  const [dtree, setDtree] = useState({
    output: [],
  });

  useEffect(() => {
    if (isHost && props.input.includes("<enter>")) {
      sendData(props.input);
    }

    const props_input = props.input.replace("<enter>", "").trim();
    console.log("cannedCommands :", cannedCommands);
    console.log(
      "props_input in cannedCommands :",
      props_input,
      props_input in cannedCommands,
      props_input
    );
    if (props_input && props_input in cannedCommands === false) {
      setCannedCommands({ [props_input]: false, ...cannedCommands });
    } else if (props_input && props_input in cannedCommands === true) {
      setCannedCommands({ ...cannedCommands, [props_input]: true });
    }
  }, [props.input]);

  useEffect(() => {
    console.log("props.prompt :", props.prompt);
    setPrompt(
      props.prompt
        ? Array.isArray(props.prompt)
          ? props.prompt.join("\n")
          : props.prompt
        : ""
    );

    const props_prompt = props.prompt
      ? Array.isArray(props.prompt)
        ? props.prompt.join("\n")
        : props.prompt
      : "";
    if (props_prompt && props_prompt in cannedCommands === false) {
      setCannedCommands({ [props_prompt]: false, ...cannedCommands });
    } else if (props_prompt && props_prompt in cannedCommands === true) {
      setCannedCommands({ ...cannedCommands, [props_prompt]: true });
    }
  }, [props.prompt]);

  useEffect(() => {
    console.log("connected: ", connected);

    if (socket && room.trim() !== "") {
      // fastMode
      false
        ? socket.emit("sendTerminal", {
            room: props.room,
            terminalData:
              dtree["output"].join("").split("\n").length >= 2
                ? dtree["output"].join("").split("\n").slice(-2)
                : [],
            length: dtree["output"].join("").split("\n").length,
            input: input,
          })
        : socket.emit(
            "sendLayout",
            connected
              ? {
                  room: props.room,
                  jsonData: [
                    {
                      code: `s0`,
                      componentType: "SerialConsole",
                      props: {
                        id: `${props.id}`,
                        terminal: dtree["output"],
                        input: input,
                        prompt: prompt,
                        room: props.room,
                        senderUuId: props.id,
                      },
                    },
                    {
                      code: `cap0`,
                      componentType: "SerialConsoleCapture",
                      props: {
                        id: `${props.id}`,
                        terminal: dtree["output"],
                        input,
                        room: props.room,
                      },
                    },
                    // AiChat,
                  ],
                  sections: [
                    {
                      title: "My ChatGPT Interaction Showcase",
                      fluid: true,
                      cols: ["6", "6"],
                      featureTypesArry: [`s0`, `cap0`],
                    },
                  ],
                }
              : {
                  room: props.room,
                  jsonData: [
                    {
                      code: `s0`,
                      componentType: "SerialConsole",
                      props: {
                        id: `${props.id}`,
                        terminal: dtree["output"],
                        input: input,
                        prompt: prompt,
                        room: props.room,
                        senderUuId: false,
                      },
                    },
                    {
                      code: `cap0`,
                      componentType: "SerialConsoleCapture",
                      props: {
                        id: `${props.id}`,
                        terminal: dtree["output"],
                        input,
                        room: props.room,
                      },
                    },
                    // AiChat,
                  ],
                  sections: [
                    {
                      title: "My ChatGPT Interaction Showcase",
                      fluid: true,
                      cols: ["6", "6"],
                      featureTypesArry: [`s0`, `cap0`],
                    },
                  ],
                }
          );
    }
  }, [connected]);

  useEffect(() => {
    console.log("toggle: ", toggle);
    sendDataToRoom("");
  }, [toggle]);

  useEffect(() => {
    setSocket(newSocket);
    getCannedCommands().then((data) => {
      setCannedCommands(data);
    });
  }, []);

  const sendCommandRoom = (command) => {
    console.log("sendCommandRoom: ", room, command);
    socket.emit("sendCommandRoom", {});
  };

  const sendRoom = (fastMode = false) => {
    console.log("sendRoom: ", room);
    setCounter(counter + 1);
    // setToggle(!toggle);

    if (socket && room.trim() !== "") {
      fastMode
        ? socket.emit("sendTerminal", {
            room: props.room,
            terminalData:
              dtree["output"].join("").split("\n").length >= 2
                ? dtree["output"].join("").split("\n").slice(-2)
                : [],
            length: dtree["output"].join("").split("\n").length,
            input: input,
          })
        : socket.emit(
            "sendLayout",
            toggle
              ? {
                  room: props.room,
                  jsonData: [
                    {
                      code: `s0`,
                      componentType: "SerialConsole",
                      props: {
                        id: `${props.id}`,
                        terminal: dtree["output"],
                        input: input,
                        prompt: prompt,
                        room: props.room,
                        senderUuId: props.id,
                      },
                    },
                    {
                      code: `cap0`,
                      componentType: "SerialConsoleCapture",
                      props: {
                        id: `${props.id}`,
                        terminal: dtree["output"],
                        input: input,
                        prompt: prompt,
                        room: props.room,
                        senderUuId: props.id,
                      },
                    },
                    // AiChat,
                  ],
                  sections: [
                    {
                      title: "My ChatGPT Interaction Showcase",
                      fluid: true,
                      cols: ["6", "6"],
                      featureTypesArry: [`s0`, `cap0`],
                    },
                  ],
                }
              : {
                  room: props.room,
                  jsonData: [
                    {
                      code: `s0`,
                      componentType: "SerialConsole",
                      props: {
                        id: `${props.id}`,
                        terminal: dtree["output"],
                        input: input,
                        prompt: prompt,
                        room: props.room,
                        senderUuId: props.id,
                      },
                    },
                    {
                      code: `cap0`,
                      componentType: "SerialConsoleCapture",
                      props: {
                        id: `${props.id}`,
                        terminal: dtree["output"],
                        input: input,
                        prompt: prompt,
                        room: props.room,
                        senderUuId: props.id,
                      },
                    },
                    // AiChat,
                  ],
                  sections: [
                    {
                      title: "My ChatGPT Interaction Showcase",
                      fluid: true,
                      cols: ["6", "6"],
                      featureTypesArry: [`s0`, `cap0`],
                    },
                  ],
                }
          );
    }
  };
  const sendDataToRoom = (input) => {
    console.log("sendDataToRoom: ", room);
    setCounter(counter + 1);

    if (socket && room.trim() !== "") {
      fastMode
        ? socket.emit("sendTerminal", {
            room: props.room,
            terminalData:
              dtree["output"].join("").split("\n").length >= 2
                ? dtree["output"].join("").split("\n").slice(-2)
                : [],
            length: dtree["output"].join("").split("\n").length,
            input: input,
          })
        : socket.emit(
            "sendLayout",
            toggle
              ? {
                  room: props.room,
                  jsonData: [
                    {
                      code: `s0`,
                      componentType: "SerialConsole",
                      props: {
                        id: `${props.id}`,
                        terminal: dtree["output"],
                        input: input,
                        prompt: prompt,
                        room: props.room,
                        senderUuId: props.id,
                      },
                    },
                    {
                      code: `cap0`,
                      componentType: "SerialConsoleCapture",
                      props: {
                        id: `${props.id}`,
                        terminal: dtree["output"],
                        input: input,
                        prompt: prompt,
                        room: props.room,
                        senderUuId: props.id,
                      },
                    },
                  ],
                  sections: [
                    {
                      title: "My ChatGPT Interaction Showcase",
                      fluid: true,
                      cols: ["6", "6"],
                      featureTypesArry: [`s0`, `cap0`],
                    },
                  ],
                }
              : {
                  room: props.room,
                  jsonData: [
                    {
                      code: `s0`,
                      componentType: "SerialConsole",
                      props: {
                        id: `${props.id}`,
                        terminal: dtree["output"],
                        input: input,
                        prompt: prompt,
                        room: props.room,
                        senderUuId: props.id,
                      },
                    },
                    {
                      code: `cap0`,
                      componentType: "SerialConsoleCapture",
                      props: {
                        id: `${props.id}`,
                        terminal: dtree["output"],
                        input: input,
                        prompt: prompt,
                        room: props.room,
                        senderUuId: props.id,
                      },
                    },
                  ],
                  sections: [
                    {
                      title: "My ChatGPT Interaction Showcase",
                      fluid: true,
                      cols: ["6", "6"],
                      featureTypesArry: [`s0`, `cap0`],
                    },
                  ],
                }
          );
    }
  };

  const endOfMessagesRef = useRef(null);

  const scrollToBottom = () => {
    endOfMessagesRef.current.scrollTop = endOfMessagesRef.current.scrollHeight;
    // endOfMessagesRef.current.scrollTo({
    //   top: endOfMessagesRef.current.scrollHeight,
    //   behavior: 'smooth'
    // });
  };

  function wait(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  useEffect(() => {
    console.log('dtree["output"]: ', dtree["output"]);

    const output = dtree["output"].join("");
    const output_check_split_len = output.split("\n").length;
    const output_check_len = output.length;
    console.log("output_check_split_len: ", output_check_split_len);
    console.log("outputSplitLen: ", outputSplitLen);
    console.log("output_check_len: ", output_check_len);
    console.log("outputLen: ", outputLen);

    if (
      output_check_split_len > outputSplitLen ||
      output.split("\n")[output_check_split_len - 1].includes("#") ||
      output.split("\n")[output_check_split_len - 1].includes("assword") ||
      output.split("\n")[output_check_split_len - 1].includes(">") ||
      output.split("\n")[output_check_split_len - 1].includes("yes")
    ) {
      sendRoom(
        output.split("\n")[output_check_split_len - 1].includes("#") ||
          output.split("\n")[output_check_split_len - 1].includes("assword") ||
          output.split("\n")[output_check_split_len - 1].includes(">") ||
          output.split("\n")[output_check_split_len - 1].includes("yes")
          ? false
          : true
      );
      scrollToBottom();
      setOutputSplitLen(output_check_split_len);
    }
    if (output_check_split_len > 1000) {
      setDtree({ output: dtree["output"].slice(-10) });
      setOutputSplitLen(0);
    }
  }, [dtree]);

  useEffect(() => {
    // !isHost && scrollToBottom();
    scrollToBottom();
  }, [props.terminal]);

  useEffect(() => {
    console.log("prompt :", prompt);
  }, [prompt]);

  async function disconnectSerial() {
    if (!port) {
      console.error("Serial port not connected.");
      return;
    }
    // setDtree((dtree) => {
    //   return {
    //     ...dtree,
    //     output: [...dtree["output"], "Disconneciting...\n"],
    //   };
    // });

    try {
      await reader.cancel();
      await port.close();
      console.log("Disconnected from serial port.");
      setPort(null);
      setConnected(false);
    } catch (error) {
      console.error("Error disconnecting from serial port:", error);
      setConnected(true);
    }
  }

  async function connectSerial() {
    // setDtree((dtree) => {
    //   return {
    //     ...dtree,
    //     output: [...dtree["output"], "Connecting...\n"],
    //   };
    // });
    try {
      selectedPort = await navigator.serial.requestPort();
      await selectedPort.open({ baudRate: 9600 });
      console.log("Connected to serial port:", selectedPort);
      startReading(selectedPort);
      setPort(selectedPort);
      setConnected(true);
      // setIsHost(props.id);
    } catch (err) {
      console.error("Error connecting to serial port:", err);
      setConnected(false);
    }
  }

  async function sendData(data) {
    console.log("sendData:", data);
    console.log("sendData replace :", data.replace("<enter>", ""));
    setInput(data.replace("<enter>", ""));
    setDtree({ output: [] });
    setOutputSplitLen(0);
    const encoder = new TextEncoder();
    const encodedData = encoder.encode(
      data.replace("<enter>", "\r").replace("<tab>", "\t")
    );

    const writer = port.writable.getWriter();
    console.log("data:", data);
    console.log("encodedData:", encodedData);
    const ctrlAlt6 = new Uint8Array([0x1E]);
    console.log("ctrlAlt6:", ctrlAlt6);
    console.log("Ctrl+Alt+6:", data.includes("Ctrl+Alt+6"));

    if (data.includes("Ctrl+Alt+6")) {
      const xChar = new TextEncoder().encode("x");
      await writer.write(ctrlAlt6);
      await writer.write(xChar);
    } else {
      await writer.write(encodedData);
    }
    console.log("Data sent:", data);
    writer.releaseLock();
  }

  function decodeData(data) {
    const decoder = new TextDecoder();
    return decoder.decode(data);
  }

  async function startReading(port) {
    const reader = port.readable.getReader();
    setReader(reader);
    while (true) {
      try {
        const { value, done } = await reader.read();
        if (done) {
          console.log("Reader done.");
          break;
        }
        const decodedData = decodeData(value);
        // setJsonData((jsonData) => {
        //   jsonData[0].props.terminal.concat([decodedData]);
        // });
        setDtree((dtree) => {
          return {
            ...dtree,
            output: [...dtree["output"], decodedData],
          };
        });
      } catch (error) {
        console.error("Error reading from serial port:", error);
        break;
      }
    }
    reader.releaseLock();
  }

  return (
    <div key={"key-" + props.id} style={{ textAlign: "left" }}>
      <div
        style={{
          textAlign: "left",
          height: "100px",
          display: "flex",
          "flex-direction": "column",
        }}
      >
        {/* <h6>ID: {isHost}</h6> */}
        {/* <h6>props.senderUuId {props.senderUuId}</h6>
        <h6>
          {props.senderUuId} is host of room {props.room}
        </h6> */}
        {/* {props.senderUuId == isHost ? (
          <h6>I am the host of room {props.room}</h6>
        ) : (
          <h6>
            {props.senderUuId} is the host of room {props.room}
          </h6>
        )} */}
        <Button
          id="toggle"
          variant={fastMode ? "primary" : "outline-primary"}
          onClick={() => setFastMode(!fastMode)}
        >
          Fast Mode: {`${fastMode ? "ON" : "OFF"}`}
        </Button>
        {!props.senderUuId && props.senderUuId !== isHost && (
          <Button
            style={{ marginTop: "auto" }}
            id="connectButton"
            onClick={async () => {
              await connectSerial();
            }}
          >
            Connect to Local Serial Console
          </Button>
        )}
        {props.senderUuId === isHost && (
          <Button
            style={{ marginTop: "auto" }}
            id="connectButton"
            variant="success"
            onClick={async () => {
              await disconnectSerial();
            }}
          >
            Disconnect from Local Serial Console
          </Button>
        )}
      </div>
      <div style={{ height: "500px", overflow: "hidden" }}>
        <pre
          id={"yourDivID-" + props.id}
          // key={"key-" + props.id}
          ref={endOfMessagesRef}
          style={{
            height: "100%",
            textAlign: "left",
            backgroundColor: "black",
            color: "greenyellow",
            fontFamily: "monospace",
            overflow: "auto",
            overflowX: "auto",
            whiteSpace: "pre",
          }}
        >
          {props.id}
          {props.senderUuId == isHost
            ? dtree["output"].map((d, i) =>
                d ? d : <h2>LoadingHere too...</h2>
              )
            : props.terminal.map((d, i) =>
                d ? d : <h2>LoadingHere too...</h2>
              )}
        </pre>
      </div>
      <Row>
        <Col md={9}>
          <React.Fragment key="{`${id}-tfrag-${d.id}`}">
            {/* <h1>{d.id}</h1> */}
            <Form.Group
              key="{`${id}-tfg-${d.id}-${d.name}`}"
              controlId="{`${id}-tcid-${d.id}-${d.name}`}"
            >
              {/* <Form.Label key="{`${id}-tfl-${d.id}-${d.name}`}">d.value</Form.Label> */}
              {/* <Form.Control type="password" placeholder="Password" /> */}
              <Form.Control
                key="input"
                // id="input"
                style={{
                  height: "100%",
                  textAlign: "left",
                  backgroundColor: "black",
                  color: "greenyellow",
                  fontFamily: "monospace",
                  overflow: "auto",
                }}
                name="input"
                type="text"
                onChange={(e) => setInput(e.target.value)}
                ref={inputRef}
                onKeyDown={async (e) => {
                  if (e.key === "Enter") {
                    e.preventDefault(); // Prevent form submission
                    await sendData(`${input}\r`);
                    props.senderUuId === isHost
                      ? await sendData(`${input}\r`)
                      : sendDataToRoom(`${input}`);
                    setInput("");
                    inputRef.current.focus();
                  }
                }}
                rows="3"
                value={input}
              />
              <Form.Control
                as="textarea"
                rows={6}
                value={prompt}
                onChange={(e) => {
                  setPrompt(e.target.value);
                }}
              />
            </Form.Group>
          </React.Fragment>
        </Col>
        <Col md={3}>
          {[
            "",
            "?",
            "show ",
            "interface ",
            "enable",
            "terminal len",
            "aaa",
            "cisco",
            "ip access-list",
          ].map((d, i) => {
            return (
              <>
                {" "}
                <Badge
                  variant="success"
                  key={`btn-${i}`}
                  onClick={() => {
                    setInput(`${d}`);
                  }}
                >
                  {d}
                </Badge>
              </>
            );
          })}
        </Col>
      </Row>
      {/* <Button
        id="sendButton"
        onClick={async () => {
          await sendData(`${input}\r`);
        }}
      >
        Send
      </Button> */}
      {/* <Button
        id="sendButton"
        onClick={async () => {
          await sendData(`\r\renable\term len 0\r show run\r`);
        }}
      >
        Test Console
      </Button> */}
      <Row>
        <Col md={6}>
          <Button
            style={{
              width: "100%",
              // height: "100px",
              marginBottom: "5px",
            }}
            id="sendAryButton"
            onClick={async () => {
              // await sendData(`${prompt}\r`);
              props.senderUuId === isHost
                ? await sendData(`${prompt}\r`)
                : sendDataToRoom(`${prompt}<enter>`);
            }}
          >
            Send Prompt
          </Button>
        </Col>
        <Col md={3}>
          <Button
            id="immediatelySend-toggle"
            variant={immediatelySend ? "primary" : "outline-primary"}
            onClick={() => setImmediatelySend(!immediatelySend)}
          >
            Immediate Send: {`${immediatelySend ? "ON" : "OFF"}`}
          </Button>
        </Col>
        <Col md={3}>
          <Button
            id="config-add"
            variant={immediatelySend ? "primary" : "outline-primary"}
            onClick={() => setPrompt(`config t\r${prompt}\n end`)}
          >
            Add Configure Terminal
          </Button>
        </Col>
      </Row>
      {/* {1 && (
        <Button id="sendRoom" onClick={sendRoom}>
          Send Room
        </Button>
      )} */}
      <br />
      <Container fluid>
        <Row>
          {Object.keys(cannedCommands)
            .filter((f) => f.includes(input))
            .map((d, i) => {
              return (
                <Col
                  lg="4"
                  md="6"
                  sm="12"
                  style={{
                    marginBottom: "5px",
                    paddingBottom: "5px",
                  }}
                >
                  <Button
                    style={{
                      width: "100%",
                      height: "100%",
                      textAlign: "left",
                    }}
                    id="sendButton"
                    variant={cannedCommands[d] ? "primary" : "outline-primary"}
                    onClick={async () => {
                      if (immediatelySend) {
                        props.senderUuId === isHost
                          ? await sendData(`${d}\r`)
                          : sendDataToRoom(`${d}<enter>`);
                      } else {
                        setPrompt(d);
                      }
                    }}
                  >
                    <pre>{d.length > 2000 ? d.slice(0, 2000) : d}</pre>
                  </Button>
                </Col>
              );
            })}
        </Row>
      </Container>
    </div>
  );
}
