import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, ToggleButton, Form } from "react-bootstrap";
import Layout from "../Layout";

export default function CheckoutLayout() {
  const [jsonData, setJsonData] = useState([
    {
      code: "ckout1",
      componentType: "StripeCheckout",
      props: {
        title: "Donate to Serial Console Project",
        description:
          "If you enjoy this project and would like to support it, please feel free to make a donation.",
        price: `price_1PCMQU2UeSQIV463oAe2JBgh`,
        price_usd: 3.0,
        mode: "subscription",
        successUrl: `https://paglipay-cyoa-express.herokuapp.com/home`,
        cancelUrl: `https://paglipay-cyoa-express.herokuapp.com/checkout`,
      },
    },
    {
      code: "ckout2",
      componentType: "StripeCheckout",
      props: {
        title: "Provide Security Footage Subscription",
        description:
          "$10.00/month - Review, download, and upload security footage from a specified timeline to share with the customer via Google Drive. Footage may be requested up to 3 times a month.",
        price: `price_1PCjOI2UeSQIV463jxctQ09q`,
        price_usd: 10.0,
        mode: "subscription",
        successUrl: `https://paglipay-react-portfolio.herokuapp.com`,
        cancelUrl: `https://paglipay-cyoa-express.herokuapp.com/checkout`,
      },
    },
    {
      code: "ckout3",
      componentType: "StripeCheckout",
      props: {
        title: "Provide Security Footage Onetime Purchase",
        description:
          "$15.00 - Review, download, and upload security footage from a specified timeline to share with the customer via Google Drive. Footage may be requested up to 3 times a month.",
        price: `price_1PCkUi2UeSQIV463fCtpQ1iA`,
        price_usd: 15.0,
        mode: "payment",
        successUrl: `https://paglipay-react-portfolio.herokuapp.com`,
        cancelUrl: `https://paglipay-cyoa-express.herokuapp.com/checkout`,
      },
    },
    {
      code: "ckout4",
      componentType: "StripeCheckout",
      props: {
        title: "Troubleshoot Security Footage at a Specific Time and Location",
        description:
          "$180.00 - Troubleshoot Security Footage at a Specific Time and Location may include driving to location, reviewing footage, and providing a solution. Actions to include but not limited to: driving to location, accessing equipment, and reporting technical issues.",
        price: `price_1PCkUi2UeSQIV463fCtpQ1iA`,
        price_usd: 180.0,
        mode: "payment",
        successUrl: `https://paglipay-react-portfolio.herokuapp.com`,
        cancelUrl: `https://paglipay-cyoa-express.herokuapp.com/checkout`,
      },
    },
    {
      code: "ckout5",
      componentType: "StripeCheckout",
      props: {
        title: "Rackmount Network Switches",
        description:
          "$10.00 - Install Rackmount Network Switches at a specific location. Actions to include but not limited to: driving to location, installing equipment, and reporting technical issues.",
        price: `price_1PDxhhDwC5eEeYFHZrT3CqQv`,
        price_usd: 10,
        mode: "payment",
        successUrl: `https://paglipay-react-portfolio.herokuapp.com`,
        cancelUrl: `https://paglipay-cyoa-express.herokuapp.com/checkout`,
      },
    },
  ]);
  const [sections, setSections] = useState([
    {
      title: "Paul Aglipay's Services Checkout",
      fluid: true,
      cols: ["4", "4", "4", "4", "4", "4", "4", "4"],
      featureTypesArry: ["ckout1", "ckout2", "ckout3", "ckout4", "ckout5"],
    },
  ]);

  useEffect(() => {
    console.log("CheckoutLayout: useEffect");
  }, []);
  return (
    <>
      <Layout jsonData={jsonData} sections={sections} />
    </>
  );
}
