import { loadStripe } from '@stripe/stripe-js';

let stripePromise;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(`pk_test_51PDodTDwC5eEeYFHNC8eha8eXPZH1RlBKmcfYRGRF9bJ2uOWf1dIezf3WjMuHbllUXYvpT903ee8BPnMPbuJDChW00vVc2dLOy`);
  }
  return stripePromise;
};

export default getStripe;