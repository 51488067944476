import React, { useState, useEffect } from "react";
import getStripe from "./lib/getStripe";
import { Form, Button, Card, Table, Image } from "react-bootstrap";

export default function StripeCheckout({
  title,
  description,
  price,
  price_usd,
  mode,
}) {
  const [customerEmail, setCustomerEmail] = useState("");
  const [enabled, setEnabled] = useState(false);
  const [quantity, setQuantity] = useState(1);

  useEffect(() => {
    setEnabled(customerEmail.length > 0);
  }, [customerEmail]);

  async function handleCheckout(e) {
    e.preventDefault();
    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout({
      lineItems: [
        {
          // price: `${process.env.NEXT_PUBLIC_STRIPE_PRICE_ID}`,
          price,
          quantity: parseInt(quantity),
        },
      ],
      mode,
      successUrl: `https://paglipay-cyoa-express.herokuapp.com/home`,
      cancelUrl: `https://paglipay-cyoa-express.herokuapp.com/checkout`,
      customerEmail: customerEmail,
    });
    console.warn(error.message);
  }

  return (
    <Card style={{ height: "100%" }}>
    <Card.Header>
      <h6>{title}</h6>
    </Card.Header>
      <Image
        src="https://stripe.com/img/documentation/checkout/marketplace.png"
        style={{ height: "50px", width: "50px" }}
        alt="Card image"
      />
      <Card.Body>
        <h6 style={{ textAlign: "left" }}>{title}</h6>
        <p style={{ textAlign: "left", minHeight:"80px" }}>{description}</p>
        <Form style={{ textAlign: "left" }}>
          <Form.Group>
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              value={customerEmail}
              onChange={(e) => {
                setCustomerEmail(e.target.value);
              }}
            />
            <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text>
          </Form.Group>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Description</th>
                <th style={{ width: "100px" }}>Quantity</th>
                <th style={{ width: "100px" }}>Price</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{title}</td>
                <td>
                  <Form.Control
                    type="number"
                    value={quantity}
                    onChange={(e) => {
                      setQuantity(e.target.value);
                    }}
                  />
                </td>
                <td>${(quantity * price_usd).toFixed(2)}</td>
              </tr>
            </tbody>
          </Table>
          <Button disabled={!enabled} onClick={handleCheckout}>
            Pay with Card
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
  //   return (
  //     <div>StripeCheckout</div>
  //   )
}
