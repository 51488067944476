import io from "socket.io-client";

// Create a socket instance
const socket = io("/", {
  reconnection: true, // Enable reconnection
  reconnectionAttempts: 5, // Number of reconnection attempts
  reconnectionDelay: 1000, // Delay between reconnection attempts (in milliseconds)
  reconnectionDelayMax: 5000, // Maximum delay between reconnection attempts
  randomizationFactor: 0.5, // Randomization factor to add randomness to reconnection attempts
});

export default socket;
