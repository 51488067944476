import React, { useEffect, useState } from "react";
import { Button, Form, Container, Col, Row } from "react-bootstrap";
import axios from "axios";

export default function Questionnaire({
  question,
  answers,
  room,
  socketId,
}) {
  const [selectedOption, setSelectedOption] = useState("");
  const [answer, setAnswer] = useState("");

  const options = answers; // Replace with your options
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setAnswer(event.target.value);
  };

  const submitAnswer = (event) => {
    event.preventDefault();
    console.log("Answer submitted to ", room, ": ", answer, " from ", socketId);
    axios
      .post(`https://automate.paglipay.info/start/${room}`, {
        jobs: [
          {
            import: "Key",
          },
          {
            True: [
              {
                import: "OpenAiObj",
              },
              {
                True: `${answer}\n\n ### Reminder: Send a single response, and send choices as a list of choices to the send_to_form function. ###`,
              },
            ],
          },
        ],
      })
      .then((response) => {
        console.log(response);
        
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div style={{ textAlign: "left" }}>
        <h6> Socket ID: { socketId && socketId }</h6>
        {/* // create a form with a question and multiple choice answers
    // create a submit button */}
        <Form>
          <Form.Group controlId="">
            <Form.Label>Question</Form.Label>
            <Form.Text className="text">{question}</Form.Text>
          </Form.Group>
          <Form.Group>
            <Form.Label>Multiple Choice Answers</Form.Label>
            {options.map((option, index) => (
              <Form.Check
                key={index}
                type="radio"
                id={`radio-${index}`}
                label={option}
                value={option}
                checked={selectedOption === option}
                onChange={handleOptionChange}
              />
            ))}
            <Form.Text className="text-muted">Answer:</Form.Text>
            <Form.Control
              as="textarea"
              rows={3}
              value={answer}
              onChange={(e) => {
                setAnswer(e.target.value);
              }}
            />

            <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text>
          </Form.Group>
          <Form.Group controlId="formBasicCheckbox">
            <Form.Check type="checkbox" label="Check me out" />
          </Form.Group>
          <Button variant="primary" type="submit" onClick={submitAnswer}>
            Submit
          </Button>
        </Form>
      </div>
    </>
  );
}
